import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { OutsideClickDirective } from '../packages/uikit/directives/outsideClick';

import App from './App.vue';
import { router } from './router';
import { i18n } from './i18n';
import './api';

import { vMaska } from 'maska';

createApp(App)
  .use(createPinia())
  .use(router)
  .use(i18n)
  .directive('click-outside', OutsideClickDirective)
  .directive('mask', vMaska)
  .mount('#app');
