const __pages_import_0__ = () => import("/src/pages/auth/sign-up.vue");
const __pages_import_1__ = () => import("/src/pages/auth/sign-in.vue");
const __pages_import_2__ = () => import("/src/pages/auth/reset-password.vue");
const __pages_import_3__ = () => import("/src/pages/terms-and-conditions.vue");
const __pages_import_4__ = () => import("/src/pages/privacy-policy.vue");
const __pages_import_5__ = () => import("/src/pages/orders.vue");
const __pages_import_6__ = () => import("/src/pages/index.vue");
const __pages_import_7__ = () => import("/src/pages/auth/account-confirm-email/[token].vue");
const __pages_import_8__ = () => import("/src/pages/auth/change-password/[token].vue");

const routes = [{"name":"auth-sign-up","path":"/auth/sign-up","component":__pages_import_0__,"props":true,"meta":{"guestRequired":true,"layout":"auth"}},{"name":"auth-sign-in","path":"/auth/sign-in","component":__pages_import_1__,"props":true,"meta":{"guestRequired":true,"layout":"auth"}},{"name":"auth-reset-password","path":"/auth/reset-password","component":__pages_import_2__,"props":true,"meta":{"guestRequired":true,"layout":"auth"}},{"name":"terms-and-conditions","path":"/terms-and-conditions","component":__pages_import_3__,"props":true},{"name":"privacy-policy","path":"/privacy-policy","component":__pages_import_4__,"props":true},{"name":"orders","path":"/orders","component":__pages_import_5__,"props":true,"meta":{"authRequired":true,"layout":"market"}},{"name":"index","path":"/","component":__pages_import_6__,"props":true,"meta":{"authRequired":true,"layout":"market"}},{"name":"auth-account-confirm-email-token","path":"/auth/account-confirm-email/:token","component":__pages_import_7__,"props":true,"meta":{"guestRequired":true,"layout":"auth"}},{"name":"auth-change-password-token","path":"/auth/change-password/:token","component":__pages_import_8__,"props":true,"meta":{"guestRequired":true,"layout":"auth"}}];

export default routes;