import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { Subject } from 'rxjs';
import { useMarketStore } from '@/stores/market';
import { getCandles } from '@/api/endpoints/marketdata/candles';
import { processServerErrors } from '@/api/common';
import { useCandleStorage } from '@/hooks/useCandleStorage';
import { Candle } from '@/api/types/marketData';

export const useChartDataStore = defineStore('chartData', () => {
  const candleSize = ref<number>(900);

  const marketStore = useMarketStore();
  const {
    activePair,
    activePairData,
  } = storeToRefs(marketStore);

  const {
    candles,
    fetchedDataFrom,
    lastCandle,
    firstCandle,
    candlesWithinLast24Hours,
    firstCandleInLast24Hours,
    currentPrice,
    firstPriceWithinLast24Hours,
    amountOfTransactionsInLast24Hours,
    getCurrentPriceByPairId,
    checkIsDataExistByPairId,
    get24HoursPercentChangeByPairId,
    clearCandles,
    appendCandles: candleStoreAppendCandles,
  } = useCandleStorage(
    activePair,
    'candlesMap',
  );

  const candlesSubject = new Subject<Candle[]>();

  const listenForNewCandles = (...args: any[]) => (
    candlesSubject.subscribe(...args)
  );

  const appendCandlesSubject = new Subject<Candle[]>();

  const listenForAppendCandles = (...args: any[]) => (
    appendCandlesSubject.subscribe(...args)
  );

  const preserveCandles = (
    newCandles: Candle[],
  ) => {
    candlesSubject.next(newCandles);
    candleStoreAppendCandles(newCandles);
  };

  const appendCandles = (
    newCandles: Candle[],
  ) => {
    appendCandlesSubject.next(newCandles);
    candleStoreAppendCandles(newCandles);
  };

  const isFetchingCandles = ref(false);

  const fetchCandles = async (
    dateFrom: string,
    dateTo: string,
  ) => {
    if (!activePairData.value) return;

    isFetchingCandles.value = true;
    const {
      result,
      data,
    } = await getCandles({
      pair: activePairData.value.alias,
      date_from: dateFrom,
      date_to: dateTo,
      size: candleSize.value,
    });

    isFetchingCandles.value = false;

    if (result) {
      clearCandles();
      preserveCandles(data.data);
    } else {
      processServerErrors(data);
    }
  };

  return {
    candleSize,
    fetchedDataFrom,
    candles,
    lastCandle,
    firstCandle,
    candlesWithinLast24Hours,
    preserveCandles,
    listenForNewCandles,
    appendCandles,
    listenForAppendCandles,
    getCurrentPriceByPairId,
    checkIsDataExistByPairId,
    get24HoursPercentChangeByPairId,
    isFetchingCandles,
    fetchCandles,
    currentPrice,
    amountOfTransactionsInLast24Hours,
    firstPriceWithinLast24Hours,
    firstCandleInLast24Hours,
  };
});
