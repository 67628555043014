<template>
  <div
    :class="$style.icon"
    :style="{
      ...(size ? {
        width: `${size}px`,
        minWidth: `${size}px`,
        height: `${size}px`,
      } : {})
    }"
  >
    <svg :class="$style.source">
      <use :xlink:href="`#icon-${icon}`" />
    </svg>
  </div>
</template>

<script lang="ts" setup>
interface IconProps {
  icon: string;
  size?: 6 | 18 | 20 | 24 | number,
}
const props = withDefaults(
  defineProps<IconProps>(),
  {
    size: 24,
  },
);
defineExpose(props);
</script>

<style lang="scss" module>
@import "../../assets/styles/utils";

.icon {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  position: relative;
}

.source {
  fill: currentColor;
  stroke: currentColor;
  position: relative;
  display: block;
  flex-grow: 1;
  width: 100%;
}
</style>
